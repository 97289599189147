import { signal } from '@angular/core';
import { LessonHistory, LessonHistoryActivity } from 'student-common-lib';
import { Signalify } from 'ui-common-lib';

function signalifyActivity(activity: LessonHistoryActivity) {
    const returnActivityHistory: Signalify<LessonHistoryActivity> = {
        ...activity,
        internalNote: signal(activity.internalNote),
        parentNote: signal(activity.parentNote),
        aiGradeAndSubject: signal(activity.aiGradeAndSubject),
        aiAttempted: signal(activity.aiAttempted),
        aiCorrect: signal(activity.aiCorrect),
        aiPercentage: signal(activity.aiPercentage),
        aiTotal: signal(activity.aiTotal)
    };

    return returnActivityHistory;
}

export function signalifyLessonHistory(lesson: LessonHistory) {
    const returnLessonHistory: Signalify<LessonHistory> = {
        ...lesson,
        lessonType: signal(lesson.lessonType),
        lessonStatus: signal(lesson.lessonStatus),
        sessionInternalNote: signal(lesson.sessionInternalNote),
        sessionParentNote: signal(lesson.sessionParentNote),
        internalNote: signal(lesson.internalNote),
        internalNoteRanking: signal(lesson.internalNoteRanking),
        parentNoteRanking: signal(lesson.parentNoteRanking),
        sessionInternalNoteRanking: signal(lesson.sessionInternalNoteRanking),
        sessionParentNoteRanking: signal(lesson.sessionParentNoteRanking),
        parentNoteApproved: signal(lesson.parentNoteApproved),
        aiNote: signal(lesson.aiNote),
        focusNote: signal(lesson.focusNote),
        parentNote: signal(lesson.parentNote),
        activities: lesson.activities.map(s => signalifyActivity(s)),
        homework: lesson.homework.map(s => signalifyActivity(s))
    };

    return returnLessonHistory;
}

