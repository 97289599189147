import { createReducer, on } from '@ngrx/store';
import { LessonEnrolment, LessonEnrolmentStage } from 'enrolment-lib';
import * as moment from 'moment';
import { LessonStatus, LessonType } from 'ui-common-lib';

import { AssessmentStatus, DIYUpdateResult, LessonHelpStatus, StreamState } from '../../../shared';
import { TutorLockResolver } from '../../helper/tutor-lock-resolver';
import {
  Lesson,
  SessionLessonTimings,
  TutorLockAction,
  TutorLockActivityType
} from '../../models';
import { LessonEntity, LessonEntityState, LessonFocusState, LessonHelpState, LessonObserveState } from '../models';
import * as LessonActions from './lesson.actions';

export interface LessonState {
  readonly teachId: number | undefined;
  readonly observeId: number | undefined;
  readonly entityIds: readonly number[];
  readonly entities: {
    readonly [lessonId: number]: LessonEntity;
  };
  readonly entityState: {
    readonly [lessonGuid: string]: LessonEntityState;
  };
  readonly timings: {
    readonly [lessonGuid: string]: SessionLessonTimings | undefined;
  };
}

interface EntityContext {
  readonly entity: LessonEntity;
  readonly entityState: LessonEntityState;
}

const initialState: LessonState = {
  teachId: undefined,
  observeId: undefined,
  entityIds: [],
  entities: {},
  entityState: {},
  timings: {}
};

function calculateDuration(startTime: Date | undefined): number | undefined {
  if (startTime) {
    const start = moment.utc(startTime);

    // If a valid start time is found, calculate the diff to now and set the time taken
    if (start.isValid()) {
      const now = moment.utc();
      return now.diff(start, 'seconds');
    }
  }

  return undefined;
}

function visible(lessonStatus: LessonStatus, lessonType: LessonType) {
  return !(lessonStatus === LessonStatus.Deferred || lessonType === LessonType.HomeMakeUp);
}

function getLessonEntityContext(focusId: number | undefined, observeId: number | undefined, sessionLesson: Lesson,
  color: number, enrolment: LessonEnrolment | undefined, inLobby = false): EntityContext {

  // Create the base entity object
  const entity: LessonEntity = {
    lessonId: sessionLesson.lesson.lessonId,
    lessonGuid: sessionLesson.lesson.lessonGuid,
    lessonPlanId: sessionLesson.lessonPlanId,
    student: sessionLesson.student,
    studentId: sessionLesson.studentId,
    preferredCurrencyId: sessionLesson.preferredCurrencyId,
    preferredCurrencyCode: sessionLesson.preferredCurrencyCode,
    studentIanaTimeZoneName: sessionLesson.studentIanaTimeZoneName,
    centreId: sessionLesson.lesson.centreId,
    gradeId: sessionLesson.gradeId ?? null,
    dateOfBirth: sessionLesson.dateOfBirth ?? null,
    regionId: sessionLesson.regionId,
    soundRegionId: sessionLesson.soundRegionId,
    subjectName: sessionLesson.subjectName,
    subjectShortCode: sessionLesson.subjectShortCode,
    levelTitle: sessionLesson.levelTitle,
    previousLessonGuid: sessionLesson.lesson.previousLessonGuid,
    previousLessonId: sessionLesson.lesson.previousLessonId,
    previousSessionId: sessionLesson.lesson.previousSessionId,
    previousLessonPlanId: sessionLesson.lesson.previousLessonPlanId,
    previousLessonDateTime: sessionLesson.lesson.previousLessonDateTime ?? null,
    previousLessonTutor: sessionLesson.lesson.previousLessonTutor ?? null,
    previousEnrolmentLessonType: sessionLesson.lesson.previousEnrolmentLessonType ?? null,
    previousText: sessionLesson.lesson.previousEnrolmentLessonType === 2 ? 'Last DIY' : 'Last Homework',
    color: color
  };

  // Separate the entity state for easier updating - via guid lookup
  const entityState: LessonEntityState = {
    activityGuid: sessionLesson.lesson.activityGuid ?? '',
    questionGuid: sessionLesson.lesson.questionGuid ?? '',
    customActivityPageGuid: sessionLesson.lesson.customActivityPageGuid,
    tutorActivityGuid: sessionLesson.tutorActivityGuid,
    tutorQuestionGuid: sessionLesson.tutorQuestionGuid,
    tutorCustomActivityPageGuid: sessionLesson.tutorCustomActivityPageGuid,
    tutorLocked: sessionLesson.tutorLocked,
    started: sessionLesson.started ?? false,
    idle: false,
    isHomeworkOnly: sessionLesson.isHomeworkOnly ?? false,
    lookingTab: true,
    lobby: inLobby,
    isOnline: sessionLesson.isOnline ?? false,
    assessmentId: sessionLesson.lesson.assessmentId ?? null,
    dropInLessonCompleted: sessionLesson.dropInLessonCompleted,
    studentPhotoUrl: '',
    studentPhotoDefault: true,
    studentImageData: '',
    assessmentStatus: sessionLesson.lesson.assessmentStatus ?? null,
    hasSkillbuilders: sessionLesson.lesson.hasSkillbuilders,
    studentOnline: sessionLesson.studentOnline ?? false,
    lessonStatus: sessionLesson.lessonStatusId,
    internalNote: sessionLesson.lesson.internalNote ?? '',
    parentNote: sessionLesson.lesson.parentNote ?? '',
    parentNoteApproved: sessionLesson.lesson.parentNoteApproved ?? false,
    focusNote: sessionLesson.lesson.focusNote ?? '',
    aiNote: sessionLesson.lesson.aiNote ?? '',
    internalNoteRanking: sessionLesson.lesson.internalNoteRanking ?? null,
    parentNoteRanking: sessionLesson.lesson.parentNoteRanking ?? null,
    startLessonNote: sessionLesson.lesson.startLessonNote ?? '',
    finishLessonNote: sessionLesson.lesson.finishLessonNote ?? '',
    startLessonNoteRanking: sessionLesson.lesson.startLessonNoteRanking ?? null,
    finishLessonNoteRanking: sessionLesson.lesson.finishLessonNoteRanking ?? null,
    publishVideo: sessionLesson.lesson.publishVideo ?? false,
    publishAudio: sessionLesson.lesson.publishAudio ?? false,
    activityGuids: (sessionLesson.lesson.activities ?? []).map(activity => activity.activityGuid),
    homeworkGuids: (sessionLesson.lesson.homework ?? []).map(activity => activity.activityGuid),
    previousHomeworkGuids: (sessionLesson.lesson.previousHomework ?? []).map(activity => activity.activityGuid),
    streamState: StreamState.Unknown,
    enrolment: enrolment,
    deviceData: sessionLesson.deviceData,
    isPinned: sessionLesson.lesson.isPinned ?? false,
    displayOrder: sessionLesson.lesson.displayOrder ?? null,
    isDIYCompatible: sessionLesson.lesson.isDIYCompatible ?? false,
    lessonType: sessionLesson.lesson.lessonType,
    studentSettings: sessionLesson.studentSettings,
    visible: visible(sessionLesson.lessonStatusId, sessionLesson.lesson.lessonType),
    connectionId: '',
    installAvailable: false,
    message: '',
    messageReload: false,
    messageError: false
  };

  // If a focus id is defined, initialize it as this lesson is focused
  // Otherwise apply the values provided
  if (sessionLesson.lesson.lessonId === focusId) {
    entityState.focus = {
      focused: true,
      duration: sessionLesson.lesson.focus.duration,
      sinceLast: undefined,
      startTime: sessionLesson.lesson.focus.startTime
    };
  } else if (sessionLesson.lesson.focus) {
    entityState.focus = {
      focused: false,
      duration: sessionLesson.lesson.focus.duration,
      sinceLast: sessionLesson.lesson.focus.sinceLast,
      startTime: sessionLesson.lesson.focus.startTime
    };
  }

  if (sessionLesson.lesson.lessonId === observeId) {
    entityState.observe = {
      observed: true,
      duration: sessionLesson.lesson.observe.duration,
      sinceLast: undefined,
      startTime: sessionLesson.lesson.observe.startTime
    };
  } else if (sessionLesson.lesson.observe) {
    entityState.observe = {
      observed: false,
      duration: sessionLesson.lesson.observe.duration,
      sinceLast: sessionLesson.lesson.observe.sinceLast,
      startTime: sessionLesson.lesson.observe.startTime
    };
  }

  if (entityState?.focus?.focused) {
    entityState.focus.sinceLast = undefined;
  }

  if (entityState?.observe?.observed) {
    entityState.observe.sinceLast = undefined;
  }

  // Finally setup the help state
  if (sessionLesson.lesson.help) {
    entityState.help = {
      duration: calculateDuration(sessionLesson.lesson.help.startTime) ?? 0,
      status: sessionLesson.lesson.help.status,
      startTime: moment.utc().toDate()
    };
  }

  return {
    entity: entity,
    entityState: entityState
  };
}

function updateLessonEntityState(lessonGuid: string, state: LessonState,
  update: (entityState: LessonEntityState) => Partial<LessonEntityState>): LessonState {
  let lessonState = state.entityState[lessonGuid];

  if (lessonState) {
    const updateState = update(lessonState);

    if (updateState) {
      lessonState = Object.assign({}, lessonState, updateState);

      return {
        teachId: state.teachId,
        observeId: state.observeId,
        entityIds: state.entityIds,
        entities: state.entities,
        timings: state.timings,
        entityState: { ...state.entityState, [lessonGuid]: lessonState }
      };
    }
  }

  return state;
}

function resolveAssessmentStatus(stage: LessonEnrolmentStage) {
  if (stage === LessonEnrolmentStage.Complete) {
    return AssessmentStatus.Enrolled;
  }
  if (stage === LessonEnrolmentStage.Skipped) {
    return AssessmentStatus.SkippedEnrolment;
  }
  return AssessmentStatus.Enrolling;
}

function updateLessonEnrolmentState(lessonGuid: string, state: LessonState,
  update: (entityState: LessonEntityState) => Partial<LessonEnrolment>): LessonState {
  const lessonState = state.entityState[lessonGuid];
  const enrolmentState = Object.assign({}, lessonState.enrolment, update(lessonState));
  return updateLessonEntityState(lessonGuid, state, () => ({
    enrolment: enrolmentState,
    assessmentStatus: resolveAssessmentStatus(enrolmentState.stage)
  }));
}

export const lessonReducer = createReducer(initialState,
  on(LessonActions.loadManyAction, (state, action) => {

    // Override all the existing lessons
    let entityIds: number[] = [];
    let entities = {};
    let entityState = {};

    for (let index = 0; index < action.sessionLessons.length; index++) {
      const sessionLesson = action.sessionLessons[index];
      const context = getLessonEntityContext(state.teachId, state.observeId, sessionLesson, index + 1, undefined);

      entityIds = [...entityIds, sessionLesson.lesson.lessonId];
      entities = { ...entities, [sessionLesson.lesson.lessonId]: context.entity };
      entityState = { ...entityState, [sessionLesson.lesson.lessonGuid]: context.entityState };
    }
    return {
      teachId: state.teachId,
      observeId: state.observeId,
      entityIds: entityIds,
      entities: entities,
      timings: state.timings,
      entityState: entityState
    };
  }),
  on(LessonActions.loadAction, (state, action) => {
    let entityIds = state.entityIds;
    let entities = state.entities;
    let entityState = state.entityState;

    if (action && entityIds && entities && entityState) {
      const sessionLesson = action.sessionLesson;

      // Skip existing lessons
      if (entityIds.includes(action.sessionLesson.lesson.lessonId)) {
        return state;
      }

      const context = getLessonEntityContext(state.teachId, state.observeId, sessionLesson, entityIds.length + 1, undefined);

      entityIds = [...entityIds, sessionLesson.lesson.lessonId];
      entities = { ...entities, [sessionLesson.lesson.lessonId]: context.entity };
      entityState = { ...entityState, [sessionLesson.lesson.lessonGuid]: context.entityState };

      return {
        teachId: state.teachId,
        observeId: state.observeId,
        entityIds: entityIds,
        entities: entities,
        timings: state.timings,
        entityState: entityState
      };
    }

    return state;
  }),
  on(LessonActions.updateAction, (state, action) => {

    // Check the lesson is already loaded and update the state values
    const lesson = action.sessionLesson.lesson;
    const entity = state.entities[lesson.lessonId];

    if (entity) {
      let lessonState = state.entityState[lesson.lessonGuid];
      const context = getLessonEntityContext(state.teachId, state.observeId, action.sessionLesson, entity.color, lessonState.enrolment, lessonState.lobby);

      // Update the lesson entity and state
      lessonState = Object.assign({}, lessonState, context.entityState);

      return {
        teachId: state.teachId,
        observeId: state.observeId,
        entityIds: state.entityIds,
        timings: state.timings,
        entities: { ...state.entities, [lesson.lessonId]: context.entity },
        entityState: { ...state.entityState, [lesson.lessonGuid]: lessonState }
      };
    }

    return state;
  }),
  on(LessonActions.updateStudentAction, (state, action) => {
    const entity = state.entities[action.lessonId];

    if (entity) {
      const updatedLesson = Object.assign({}, entity, {
        student: { givenName: action.givenName, familyName: action.familyName },
        dateOfBirth: action.dateOfBirth,
        gradeId: action.gradeId,
        regionId: action.regionId,
        levelTitle: action.levelTitle
      }) as LessonEntity;

      return {
        teachId: state.teachId,
        observeId: state.observeId,
        entityIds: state.entityIds,
        timings: state.timings,
        entities: { ...state.entities, [action.lessonId]: updatedLesson },
        entityState: state.entityState
      };
    }

    return state;
  }),
  on(LessonActions.updatePlanAction, (state, action) => {
    // Check the lesson is already loaded and update the state values
    const lesson = action.lesson;
    const entity = state.entities[lesson.lessonId];
    const entityState = { ...state.entityState[lesson.lessonGuid] };

    if (entity) {
      // Update the lesson entity and state
      entityState.activityGuids = (lesson.activities ?? []).map(activity => activity.activityGuid);
      entityState.homeworkGuids = (lesson.homework ?? []).map(activity => activity.activityGuid);

      return {
        teachId: state.teachId,
        observeId: state.observeId,
        entityIds: state.entityIds,
        timings: state.timings,
        entities: { ...state.entities, [lesson.lessonId]: entity },
        entityState: { ...state.entityState, [lesson.lessonGuid]: entityState }
      };
    }

    return state;
  }),
  on(LessonActions.updateHasSkillbuildersAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      hasSkillbuilders: action.hasSkillbuilders
    }));
  }),
  on(LessonActions.loadEnrolmentAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      enrolment: action.lessonEnrolment,
      assessmentStatus: resolveAssessmentStatus(action.lessonEnrolment.stage)
    }));
  }),
  on(LessonActions.clearMessageAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      message: '',
      messageReload: false,
      messageError: false
    }));
  }),
  on(LessonActions.updateLessonDoItYourselfAction, (state, action) => {
    const student = state.entities[action.lessonId].student;

    switch (action.updateResult) {
      case DIYUpdateResult.SuccessToHomeMakeupCreated:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          isOnline: action.isOnline,
          lessonType: action.lessonType,
          lessonStatus: action.lessonStatus,
          visible: visible(action.lessonStatus, action.lessonType),
          message: action.updateFromParent ?
            `${student.givenName}s' lesson has been changed to a DIY on the parent portal and will not be in this session.` :
            'DIY Lesson created for student. DIY lessons contain random questions and activities related to current lesson plan.',
          messageReload: true,
          messageError: false
        }));
      case DIYUpdateResult.SuccessToHomeMakeupNotCreated:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          isOnline: action.isOnline,
          lessonType: action.lessonType,
          lessonStatus: action.lessonStatus,
          visible: visible(action.lessonStatus, action.lessonType),
          message: action.updateFromParent ?
            `${student.givenName}s' lesson has been changed to a DIY on the parent portal and will not be in this session.` :
            'DIY Lesson was previously DIY generated and has not been changed.',
          messageReload: false,
          messageError: false
        }));
      case DIYUpdateResult.SuccessToScheduled:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          isOnline: action.isOnline,
          lessonType: action.lessonType,
          lessonStatus: action.lessonStatus,
          visible: visible(action.lessonStatus, action.lessonType),
          message: 'DIY Lesson has been changed back to a normal lesson - lesson will now reload.',
          messageReload: true,
          messageError: false
        }));
      case DIYUpdateResult.FailureStudentHasAttended:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          message: 'Cannot update to DIY - student has attended lesson. Once attended the lesson cannot be changed to DIY.',
          messageReload: false,
          messageError: true
        }));
      case DIYUpdateResult.FailureLessonNotDIYCompatible:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          message: 'Cannot update to DIY - lesson is not DIY compatible. Update the lesson plan and try again.',
          messageReload: false,
          messageError: true
        }));
      default:
        return state;
    }
  }),
  on(LessonActions.updateStartLessonNoteAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      startLessonNote: action.note,
      startLessonNoteRanking: action.noteRanking
    }));
  }),
  on(LessonActions.updateFinishLessonNoteAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      finishLessonNote: action.note,
      finishLessonNoteRanking: action.noteRanking
    }));
  }),
  on(LessonActions.updateEnrolmentProgressAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      stage: action.enrolmentStage
    }));
  }),
  on(LessonActions.updateEnrolmentPaymentOptionsAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      startDate: action.startDate,
      debitDay: action.debitDay,
      upfrontPaymentOption: action.upfrontPaymentOption
    }));
  }),
  on(LessonActions.updateEnrolmentPaymentEntryAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      assessorEnteringPayment: action.assessorEnteringPayment
    }));
  }),
  on(LessonActions.updateEnrolmentSubjectsAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      subjects: action.subjects
    }));
  }),
  on(LessonActions.updateEnrolmentSessionsAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      sessionSchedules: action.sessions
    }));
  }),
  on(LessonActions.updateEnrolmentBundleTypeAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      bundleOfferTypeId: action.bundleOfferTypeId
    }));
  }),
  on(LessonActions.updateEnrolmentBundleCurrencyAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      bundleCurrency: action.bundleCurrency
    }));
  }),
  on(LessonActions.updateEnrolmentBundleAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      billingInterval: action.billingInterval,
      bundleOfferId: action.bundleOfferId
    }));
  }),
  on(LessonActions.updateEnrolmentCustomerRepresentsAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      customerRepresentsOrganisation: action.customerRepresentsOrganisation
    }));
  }),
  on(LessonActions.updateEnrolmentAccountCreatedAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      parentPortalCreated: true
    }));
  }),
  on(LessonActions.startedLookingTabAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      lookingTab: true
    }));
  }),
  on(LessonActions.stoppedInteractingAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      lookingTab: false
    }));
  }),
  on(LessonActions.stoppedInteractingAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      idle: true
    }));
  }),
  on(LessonActions.startedInteractingAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      idle: false
    }));
  }),
  on(LessonActions.installAvailableAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      installAvailable: action.available
    }));
  }),
  on(LessonActions.joinLessonCompletedAction, (state, action) => {
    console.log(`SignalR ConnectionId:${action.connectionId} - ${action.lessonGuid}`);
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      connectionId: action.connectionId
    }));
  }),
  on(LessonActions.deferLessonCompletedAction, (state, action) => {
    const entityState = state.entityState[action.lessonGuid];
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      lessonStatus: LessonStatus.Deferred,
      visible: visible(LessonStatus.Deferred, entityState.lessonType)
    }));
  }),
  on(LessonActions.attendLessonCompletedAction, (state, action) => {
    const entityState = state.entityState[action.lessonGuid];
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      lessonStatus: LessonStatus.Attended,
      visible: visible(LessonStatus.Attended, entityState.lessonType)
    }));
  }),
  on(LessonActions.openLobbyAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      lobby: true
    }));
  }),
  on(LessonActions.updateAssessmentStatusAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      assessmentStatus: action.assessmentStatus
    }));
  }),
  on(LessonActions.dropInLessonCompletedAction, (state, action) => {

    return updateLessonEntityState(action.lessonGuid, state, () => ({
      dropInLessonCompleted: true
    }));
  }),
  on(LessonActions.updateAssessmentAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      assessmentId: action.assessmentId
    }));
  }),
  on(LessonActions.updateNotesCancelledAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      parentNote: action.parentNote,
      aiNote: action.aiNote,
      parentNoteApproved: action.parentNoteApproved
    }));
  }),
  on(LessonActions.updateNotesAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      internalNote: action.internalNote,
      parentNote: action.parentNote,
      aiNote: action.aiNote,
      focusNote: action.focusNote,
      internalNoteRanking: action.internalNoteRanking,
      parentNoteRanking: action.parentNoteRanking,
      parentNoteApproved: action.parentNoteApproved
    }));
  }),
  on(LessonActions.studentImageDataAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      studentImageData: action.imageData
    }));
  }),
  on(LessonActions.studentPhotoUpdateAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      studentPhotoUrl: action.studentPhotoUrl,
      studentPhotoDefault: action.studentPhotoDefault
    }));
  }),
  on(LessonActions.studentPublishDeniedAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      streamState: StreamState.Denied
    }));
  }),
  on(LessonActions.studentPublishFailureAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      streamState: StreamState.Failure
    }));
  }),
  on(LessonActions.studentPublishStreamingAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      streamState: StreamState.Streaming
    }));
  }),
  on(LessonActions.updateTimingsAction, (state, action) => {
    const timings = Object.assign({}, action.timings);
    const newState: LessonState = {
      teachId: state.teachId,
      observeId: state.observeId,
      entityIds: state.entityIds,
      entities: state.entities,
      timings: { ...state.timings, [action.lessonGuid]: timings },
      entityState: state.entityState
    };
    return newState;
  }),
  on(LessonActions.followAction, (state, action) => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        tutorActivityGuid: entityState.activityGuid,
        tutorQuestionGuid: entityState.questionGuid,
        tutorCustomActivityPageGuid: entityState.customActivityPageGuid,
        tutorLocked: false
      }));
    }

    return state;
  }),
  on(LessonActions.viewActivityAction, (state, action) => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        tutorActivityGuid: action.activityGuid,
        tutorQuestionGuid: action.activityGuid === entityState.activityGuid ? entityState.questionGuid : undefined,
        tutorLocked: action.activityGuid !== '' && action.activityGuid !== entityState.activityGuid,
        tutorCustomActivityPageGuid: action.activityGuid === entityState.activityGuid ? entityState.tutorCustomActivityPageGuid : undefined
      }));
    }

    return state;
  }),
  on(LessonActions.viewQuestionAction, (state, action) => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      const tutorLocked = new TutorLockResolver(entityState, {
        tutorLockActivityType: TutorLockActivityType.ComputerActivity,
        tutorLockAction: TutorLockAction.TutorQuestionOpenAction,
        questionGuid: action.questionGuid
      }).isTutorLocked();

      return updateLessonEntityState(action.lessonGuid, state, () => ({
        tutorActivityGuid: action.activityGuid,
        tutorQuestionGuid: action.questionGuid,
        tutorLocked: tutorLocked,
        tutorCustomActivityPageGuid: undefined
      }));
    }

    return state;
  }),
  on(LessonActions.closeLobbyAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      lobby: false,
      lookingTab: false
    }));
  }),
  on(LessonActions.openAction, (state, action) => {
    return updateLessonEntityState(action.sessionLesson.lesson.lessonGuid, state, () => ({
      started: true,
      isHomeworkOnly: action.sessionLesson.isHomeworkOnly,
      studentOnline: true
    }));
  }),
  on(LessonActions.closeAction, LessonActions.disconnectAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, lessonState => {

      // Update the focus timer
      let focus = lessonState.focus;

      if (focus?.focused) {
        const duration = calculateDuration(focus.startTime);

        if (duration) {
          focus = {
            focused: focus.focused,
            duration: focus.duration + duration,
            sinceLast: focus.sinceLast,
            startTime: moment.utc().toDate()
          };
        }
      }

      return {
        studentOnline: false,
        lobby: false,
        focus: focus,
        help: undefined,
        lookingTab: false
      };
    });
  }),
  on(LessonActions.toggleOnlineAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, entityState => ({
      publishAudio: action.isOnline ? entityState.publishAudio : false,
      publishVideo: action.isOnline ? entityState.publishVideo : false,
      isOnline: action.isOnline,
      help: action.isOnline ? entityState.help : undefined
    }));
  }),
  on(LessonActions.toggleVideoAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      publishVideo: action.publish
    }));
  }),
  on(LessonActions.toggleAudioAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      publishAudio: action.publish
    }));
  }),
  on(LessonActions.updatePinnedAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      isPinned: action.isPinned
    }));
  }),
  on(LessonActions.updateLessonOrderAction, (state, action) => {
    let entityState = state;
    for (const lessonOrder of action.lessonOrder) {
      entityState = updateLessonEntityState(lessonOrder.lessonGuid, entityState, () => ({
        isPinned: lessonOrder.isPinned,
        displayOrder: lessonOrder.displayOrder
      }));
    }
    return entityState;
  }),
  on(LessonActions.startTeachingAction, (state, action) => {

    // For focus we get the lesson id (from the url), but need the lesson guid to update the state
    // We there need to query the entities to get the guid
    const entity = state.entities[action.lessonId];

    let newState = state;

    if (entity) {
      newState = updateLessonEntityState(entity.lessonGuid, state, lessonState => {
        const focus: LessonFocusState = {
          focused: true,
          duration: lessonState.focus ? lessonState.focus.duration : 0,
          sinceLast: undefined,
          startTime: moment.utc().toDate()
        };

        return {
          publishAudio: true,
          focus: focus,
          help: undefined
        };
      });
    }

    return {
      teachId: action.lessonId,
      observeId: state.observeId,
      timings: state.timings,
      entityIds: newState.entityIds,
      entities: newState.entities,
      entityState: newState.entityState
    };
  }),
  on(LessonActions.stopTeachingAction, (state, action) => {
    const newState = updateLessonEntityState(action.lessonGuid, state, lessonState => {
      if (lessonState?.focus?.focused) {
        const focus: LessonFocusState = {
          focused: false,
          duration: lessonState.focus.duration || 0,
          sinceLast: 0,
          startTime: moment.utc().toDate()
        };

        return {
          publishAudio: false,
          focus: focus
        };
      }

      return {};
    });

    return {
      teachId: undefined,
      observeId: newState.observeId,
      entityIds: newState.entityIds,
      entities: newState.entities,
      timings: newState.timings,
      entityState: newState.entityState
    };
  }),
  on(LessonActions.startObservingAction, (state, action) => {

    // For focus we get the lesson id (from the url), but need the lesson guid to update the state
    // We there need to query the entities to get the guid
    const entity = state.entities[action.lessonId];

    let newState = state;

    if (entity) {
      newState = updateLessonEntityState(entity.lessonGuid, state, lessonState => {
        const observe: LessonObserveState = {
          observed: true,
          duration: lessonState.observe ? lessonState.observe.duration : 0,
          sinceLast: undefined,
          startTime: moment.utc().toDate()
        };

        return {
          observe: observe
        };
      });
    }

    return {
      teachId: newState.teachId,
      observeId: action.lessonId,
      entityIds: newState.entityIds,
      entities: newState.entities,
      timings: newState.timings,
      entityState: newState.entityState
    };
  }),
  on(LessonActions.stopObservingAction, (state, action) => {
    const newState = updateLessonEntityState(action.lessonGuid, state, lessonState => {
      if (lessonState?.observe?.observed) {
        const observe: LessonObserveState = {
          observed: false,
          duration: lessonState.observe.duration || 0,
          sinceLast: 0,
          startTime: moment.utc().toDate()
        };

        return {
          observe: observe
        };
      }

      return {};
    });

    return {
      teachId: newState.teachId,
      observeId: undefined,
      entityIds: newState.entityIds,
      entities: newState.entities,
      timings: newState.timings,
      entityState: newState.entityState
    };
  }),
  on(LessonActions.requestHelpAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => {
      const help: LessonHelpState = {
        duration: 0,
        status: LessonHelpStatus.Requested,
        startTime: moment.utc().toDate()
      };

      return {
        help: help
      };
    });
  }),
  on(LessonActions.acknowledgeHelpAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, lessonState => {

      let help: LessonHelpState | undefined;
      if (lessonState.help) {
        help = {
          duration: lessonState.help.duration,
          status: LessonHelpStatus.Acknowledged,
          startTime: lessonState.help.startTime
        };
      }

      return {
        help: help
      };
    });
  }),
  on(LessonActions.clearHelpAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => {
      return {
        help: undefined
      };
    });
  }),
  on(LessonActions.openActivityAction, (state, action) => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      entityState.activityGuid = action.activityGuid;
      const tutorLocked = new TutorLockResolver(entityState, {
        tutorLockActivityType: TutorLockActivityType.ComputerActivity,
        tutorLockAction: TutorLockAction.StudentQuestionOpenAction,
        questionGuid: entityState.questionGuid
      }).isTutorLocked();
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        activityGuid: action.activityGuid,
        lessonStatus: entityState.lessonStatus === LessonStatus.Ready ? LessonStatus.Attended : entityState.lessonStatus,
        tutorActivityGuid: tutorLocked ? entityState.tutorActivityGuid : action.activityGuid,
        tutorLocked: tutorLocked
      }));
    }

    return state;
  }),
  on(LessonActions.closeActivityAction, (state, action) => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        activityGuid: undefined,
        tutorActivityGuid: entityState.tutorLocked ? entityState.tutorActivityGuid : undefined
      }));
    }

    return state;
  }),
  on(LessonActions.openQuestionAction, (state, action) => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      const tutorLocked = new TutorLockResolver(entityState, {
        tutorLockActivityType: TutorLockActivityType.ComputerActivity,
        tutorLockAction: TutorLockAction.StudentQuestionOpenAction,
        questionGuid: action.questionGuid
      }).isTutorLocked();
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        questionGuid: action.questionGuid,
        tutorLocked: tutorLocked,
        tutorQuestionGuid: tutorLocked ? entityState.tutorQuestionGuid : action.questionGuid,
        customActivityPageGuid: undefined,
        tutorCustomActivityPageGuid: tutorLocked ? entityState.tutorCustomActivityPageGuid : undefined
      }));
    }

    return state;
  }),
  on(LessonActions.closeQuestionAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      questionGuid: undefined,
      customActivityPageGuid: undefined
    }));
  }),
  on(LessonActions.addSkillBuilderAction, (state): LessonState => {
    return state;
  }),
  on(LessonActions.openCustomActivityQuestion, (state, action): LessonState => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      const tutorLocked = new TutorLockResolver(entityState, {
        tutorLockActivityType: TutorLockActivityType.CustomActivity,
        tutorLockAction: TutorLockAction.StudentQuestionOpenAction,
        questionGuid: action.pageGuid
      }).isTutorLocked();
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        questionGuid: undefined,
        tutorLocked: tutorLocked,
        activityGuid: action.activityGuid,
        tutorQuestionGuid: tutorLocked ? entityState.tutorQuestionGuid : undefined,
        customActivityPageGuid: action.pageGuid,
        tutorCustomActivityPageGuid: tutorLocked ? entityState.tutorCustomActivityPageGuid : action.pageGuid
      }));
    }

    return state;
  }),
  on(LessonActions.closeCustomActivityQuestion, (state, action): LessonState => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      customActivityPageGuid: undefined,
      questionGuid: undefined
    }));
  }),
  on(LessonActions.viewCustomActivityQuestion, (state, action): LessonState => {
    const entityState = { ...state.entityState[action.lessonGuid] };
    if (entityState) {
      const tutorLocked = new TutorLockResolver(entityState, {
        tutorLockActivityType: TutorLockActivityType.CustomActivity,
        tutorLockAction: TutorLockAction.TutorQuestionOpenAction,
        questionGuid: action.pageGuid
      }).isTutorLocked();
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        tutorQuestionGuid: undefined,
        tutorCustomActivityPageGuid: action.pageGuid,
        tutorLocked: tutorLocked
      }));
    }
    return state;
  })
);
