/*eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Severity } from 'message-lib';
import * as moment from 'moment';
import { uniqueStudentName } from 'pipes-directives-lib';
import { EMPTY, Observable, of, tap } from 'rxjs';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { LessonActivityType, LessonHelpStatus, LessonQuestion, SessionActivityType } from '../../../shared';
import { Alert, AlertSource, LessonOrder, SessionLesson } from '../../models';
import { MessagingService, SessionService } from '../../services';
import * as fromActivity from '../activity';
import { ActivityContext, ActivityUpdateContext } from '../activity';
import * as fromAlerts from '../alerts';
import * as fromLesson from '../lesson';
import * as fromPlan from '../plan';
import * as fromQuestion from '../question';
import { QuestionContext } from '../question';
import * as fromSession from '../session';
import * as fromState from '../state';
import { TutorState } from '../state';

interface LessonOrderState {
  lesson: SessionLesson;
  displayOrder: number;
  isPinned: boolean;
  requiresHelp: boolean;
  pinnedChanged: boolean;
}

@Injectable()
export class LessonEffects {

  readonly #actions$ = inject(Actions);
  readonly #sessionService = inject(SessionService);
  readonly #store = inject(Store<TutorState>);
  readonly #messagingService = inject(MessagingService);

  // don't use switchmap for these as it cancels requests so not all student photos load

  studentPhotoLoad$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.studentPhotoLoadAction),
      mergeMap(action => this.#sessionService.getStudentPhoto(action.studentId)
        .pipe(
          mergeMap(response =>
            [fromLesson.studentPhotoUpdateAction(
              {
                lessonGuid: action.lessonGuid,
                studentPhotoUrl: response.body ? window.URL.createObjectURL(response.body) : '',
                studentPhotoDefault: response.headers.get('image-default') === 'true'
              })]
          )
        )
      )
    );
  });

  checkForBirthdays$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.loadManyAction),
      concatLatestFrom(
        () => this.#store.select(fromState.selectTutorSettings)
      ),
      switchMap(([action, tutorSettings]) => {
        if (action.sessionLessons && tutorSettings?.showBirthdayAlerts) {
          const allStudents = action.sessionLessons.map(lesson => lesson.student);
          const rangeMilliseconds = 7 * 24 * 60 * 60 * 1000;
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const startRange = new Date(today.getTime() - rangeMilliseconds);
          const endRange = new Date(today.getTime() + rangeMilliseconds);
          for (const sessionLesson of action.sessionLessons) {
            if (sessionLesson.dateOfBirth) {
              const birthday = new Date(startRange.getFullYear(),
                sessionLesson.dateOfBirth.month - 1, sessionLesson.dateOfBirth.day, 0, 0, 0, 0);
              if (birthday < startRange) {
                birthday.setFullYear(birthday.getFullYear() + 1);
              }
              if (birthday >= startRange && birthday <= endRange) {
                const name = uniqueStudentName(sessionLesson.student, allStudents);
                const message = birthday > today 
                ? `It is ${name}'s birthday on ${moment(birthday).format('dddd, D MMM YYYY')}!` 
                : birthday < today 
                  ? `It was ${name}'s birthday on ${moment(birthday).format('dddd, D MMM YYYY')}!` 
                  : `It is ${name}'s birthday today!`;
                this.#store.dispatch(fromAlerts.addAlertAction(
                  {
                    alert:
                      new Alert(Severity.Info, message, new Date(), AlertSource.BirthdayStatus, sessionLesson.lesson.lessonId, false)
                  }));
              }
            }
          }
        }

        return EMPTY;
      }
      )
    );
  }, { dispatch: false });

  open$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.openAction),
      switchMap(action => {
        const activityContexts: ActivityUpdateContext[] = [];
        const questions: LessonQuestion[] = [];

        if (action.sessionLesson.lesson.activities) {
          for (const activity of action.sessionLesson.lesson.activities) {
            activityContexts.push({ activity: activity, sessionActivityType: SessionActivityType.Activity });

            if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
              for (const question of activity.computer.questions) {
                questions.push(question);
              }
            }

            if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
              for (const question of activity.timedComputer.questions) {
                questions.push(question);
              }
            }
          }
        }

        if (action.sessionLesson.lesson.homework) {
          for (const activity of action.sessionLesson.lesson.homework) {
            activityContexts.push({ activity: activity, sessionActivityType: SessionActivityType.Activity });

            if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
              for (const question of activity.computer.questions) {
                questions.push(question);
              }
            }

            if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
              for (const question of activity.timedComputer.questions) {
                questions.push(question);
              }
            }
          }
        }

        return [
          fromLesson.updateAction({ sessionLesson: action.sessionLesson }),
          fromActivity.updateAction({ lessonGuid: action.sessionLesson.lesson.lessonGuid, activityContexts: activityContexts }),
          fromQuestion.updateAction({ lessonGuid: action.sessionLesson.lesson.lessonGuid, questions: questions })
        ];
      })
    );
  });

  disconnect$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.disconnectAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSessionLessons)
      ),
      switchMap(([action, lessons]) => {

        // Find the disconnected lesson and ensure the timer is stopped if it has an active question
        const lesson = lessons.find(current => current && current.lessonGuid === action.lessonGuid);

        if (lesson?.questionGuid) {
          return of(
            fromQuestion.endTimerAction({ questionGuid: lesson.questionGuid })
          );
        }

        return EMPTY;
      })
    );
  });

  update$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.updatePlanAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSessionLessons)
      ),
      switchMap(([action, lessons]) => {
        const activities: ActivityContext[] = [];
        const questions: QuestionContext[] = [];
        const lesson = lessons.find(l => l && l.lessonId === action.lesson.lessonId);

        const actions: Action[] = [];
        if (lesson) {
          const inStoreActivities = [...lesson.activities, ...lesson.homework];

          if (action.lesson.activities) {
            for (const activity of action.lesson.activities) {
              if (!inStoreActivities.some(a => a.activityGuid === activity.activityGuid)) {
                // This is a new activity add activity and corresponding questions to the insert list.
                activities.push({ sessionActivityType: SessionActivityType.Activity, lessonGuid: lesson.lessonGuid, activity: activity });
                if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                  for (const question of activity.computer.questions) {
                    questions.push({ lessonGuid: lesson.lessonGuid, question: question });
                  }
                }

                if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                  for (const question of activity.timedComputer.questions) {
                    questions.push({ lessonGuid: lesson.lessonGuid, question: question });
                  }
                }
              }
            }
          }

          if (action.lesson.homework) {
            for (const activity of action.lesson.homework) {
              if (!inStoreActivities.some(a => a.activityGuid === activity.activityGuid)) {
                // This is a new activity add activity and corresponding questions to the insert list.
                activities.push({ sessionActivityType: SessionActivityType.Homework, lessonGuid: lesson.lessonGuid, activity: activity });
                if (activity.lessonActivityType === LessonActivityType.Computer && activity.computer) {
                  for (const question of activity.computer.questions) {
                    questions.push({ lessonGuid: lesson.lessonGuid, question: question });
                  }
                }

                if (activity.lessonActivityType === LessonActivityType.TimedComputer && activity.timedComputer) {
                  for (const question of activity.timedComputer.questions) {
                    questions.push({ lessonGuid: lesson.lessonGuid, question: question });
                  }
                }
              }
            }
          }
          actions.push(fromLesson.updateHasSkillbuildersAction({ lessonGuid: lesson.lessonGuid, hasSkillbuilders: action.lesson.hasSkillbuilders }));
        }

        // insert the activities and questions in store.
        actions.push(
          fromActivity.loadManyAction({ contexts: activities }),
          fromQuestion.loadManyAction({ contexts: questions })
        );

        return actions;
      })
    );
  });

  studentStreamConnected$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.studentStreamConnected),
      concatLatestFrom(() => this.#store.select(fromLesson.selectTeachingLesson)),
      switchMap(([action, teachingLesson]) => {

        const actions: Action[] = [];

        if (teachingLesson && action.lessonGuid === teachingLesson.lessonGuid) {
          actions.push(fromLesson.restartTeaching());
        }

        return actions;
      })
    );
  });

  restartTeaching$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.restartTeaching),
      concatLatestFrom(() => this.#store.select(fromLesson.selectTeachingLesson)),
      switchMap(([_action, teachingLesson]) => {

        const actions: Action[] = [];

        if (teachingLesson) {
          actions.push(
            fromLesson.stopTeachingAction({ lessonGuid: teachingLesson.lessonGuid }),
            fromLesson.startTeachingAction({ lessonId: teachingLesson.lessonId })
          );
        }

        return actions;
      })
    );
  });

  addSkillBuilder$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.addSkillBuilderAction),
      switchMap(action => [fromPlan.updateSkillbuildersAction(
        {
          lessonPlanId: action.lessonPlanId,
          lessonPlan: action.lessonPlan
        })])
    );
  });

  orderChangedByLessonGuid$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.acknowledgeHelpAction, fromLesson.requestHelpAction, fromLesson.toggleOnlineAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSessionLessons)
      ), switchMap(([action, lessons]) => {
        return this.#calculateOrder(lessons.find(lesson => lesson.lessonGuid === action.lessonGuid), lessons);
      })
    );
  });

  orderChangedInitial$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.startTeachingAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSessionLessons),
        this.#store.select(fromSession.selectSessionId)
      ), switchMap(([action, lessons, sessionId]) => {
        const actions: Action[] = this.#calculateOrder(lessons.find(lesson => lesson.lessonId === action.lessonId), lessons,
          false);
        actions.push(fromSession.publishAudioOnAction({ sessionId: sessionId }));
        return actions;
      })
    );
  });

  orderChangedByLessonId$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.updatePinnedAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSessionLessons)
      ), switchMap(([action, lessons]) => {
        return this.#calculateOrder(lessons.find(lesson => lesson.lessonId === action.lessonId), lessons,
          true);
      })
    );
  });

  completeDropInLesson$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.completeDropInLessonAction),
      tap(action => this.#messagingService.completeDropInLesson(action.lessonId).subscribe())
    );
  },
    { dispatch: false });

  #calculateOrder(sessionLesson: SessionLesson | undefined, lessons: SessionLesson[], pinnedChanged = false): Action[] {
    if (!sessionLesson) {
      return [];
    }
    const lessonOrderState = {
      lesson: sessionLesson,
      displayOrder: 0,
      isPinned: sessionLesson.isPinned,
      requiresHelp: this.#requiresHelp(sessionLesson),
      pinnedChanged: pinnedChanged
    } as LessonOrderState;

    const proposedOrder = lessons.map(lesson => ({
      lesson: lesson,
      displayOrder: lesson.displayOrder,
      isPinned: lesson.isPinned,
      requiresHelp: this.#requiresHelp(lesson)
    } as LessonOrderState))
      .filter(order => order.lesson.lessonId !== sessionLesson.lessonId)
      .sort((a, b) => !a.displayOrder || !b.displayOrder ? 999 : a.displayOrder - b.displayOrder);

    let insertIndex = 0;
    if (lessonOrderState.isPinned) {
      if (!lessonOrderState.requiresHelp) {
        insertIndex = this.#getInsertionIndexAfter(proposedOrder, state => state.requiresHelp);
      }
    } else {
      insertIndex = lessonOrderState.requiresHelp ?
        this.#getInsertionIndexAfter(proposedOrder, state => state.requiresHelp && state.isPinned) :
        this.#getInsertionIndexAfter(proposedOrder, state => state.isPinned);
    }

    proposedOrder.splice(insertIndex, 0, lessonOrderState);
    const updated = this.#calculateUpdated(lessons, proposedOrder);
    if (updated.length > 0) {
      this.#sessionService.updateSessionLessonOrder(updated).subscribe();
      return [fromLesson.updateLessonOrderAction({ lessonOrder: updated })];
    }

    return [];
  }

  #requiresHelp(lesson: SessionLesson | undefined) {
    return lesson?.help?.status === LessonHelpStatus.Acknowledged || lesson?.help?.status === LessonHelpStatus.Requested;
  }

  #getInsertionIndexAfter(lessons: LessonOrderState[], after: (state: LessonOrderState) => boolean): number {
    const index = [...lessons].reverse().findIndex(v => after(v));
    return index === -1 ? 0 : lessons.length - index;
  }

  #calculateUpdated(originalLessons: SessionLesson[], proposed: LessonOrderState[]): LessonOrder[] {
    const results: LessonOrder[] = [];

    for (let i = 0; i < proposed.length; i++) {
      proposed[i].displayOrder = i + 1;
    }

    for (const originalLesson of originalLessons) {
      const proposedOrder = proposed.find(state => state.lesson.lessonId === originalLesson.lessonId);
      if (proposedOrder && (proposedOrder.displayOrder !== originalLesson.displayOrder || proposedOrder.pinnedChanged)) {
        results.push({ lessonId: originalLesson.lessonId, lessonGuid: originalLesson.lessonGuid, isPinned: proposedOrder.isPinned, displayOrder: proposedOrder.displayOrder });
      }
    }

    return results;
  }

}
